<!--  -->
<template>
  <div
    class="
      home
      page-template
      page-template-template-home
      page-template-template-home-php
      page
      page-id-11
      wpb-js-composer
      js-comp-ver-6.4.2
      vc_responsive
    "
  >
    <Headers></Headers>
    <div id="content" class="site-content">
      <div id="primary" class="content-area">
        <main id="main" class="site-main" role="main">
          <div class="content_container address">
            <!-- 头部banner图 -->
            <div class="section_banner_container">
              <div class="section_banner">
                <div class="container">
                  <div class="row">
                    <div class="col-xs-12 col-sm-11">
                      <div class="section_banner_content">
                        <h1 class="section_banner_heading">盈喜地址</h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 新闻列表 -->
            <div id="fh5co-main">
              <div class="fh5co-narrow-content" style="padding-bottom: 0">
                <h3
                  style="
                    font-family: 微软雅黑;
                    font-size: 24px;
                    color: #000;
                    font-weight: 700;
                  "
                >
                  盈喜地址
                </h3>
                <div class="row row-bottom-padded-md" style="padding-bottom: 0">
                  <div
                    class="
                      col-md-6
                      animate-box
                      syd-sessages
                      fadeInLeft
                      animated
                    "
                    data-animate-effect="fadeInLeft"
                  >
                    <a href="https://j.map.baidu.com/78/xPfJ" target="_blank"
                      ><img src="../assets/address.png" alt=""
                    /></a>
                  </div>
                  <div
                    class="col-md-6 animate-box fadeInLeft animated"
                    data-animate-effect="fadeInLeft"
                  >
                    <div class="tpl_tle">
                      <h4>ATXG</h4>
                      <h5>ADDENTAX GROUP CORP.</h5>
                      <p>盈喜集团</p>
                    </div>
                    <!-- 修改 2016.6.19 -->
                    <strong style="font-size: 18px">集团简介</strong>
                    <p
                      style="
                        text-indent: 2em;
                        margin-top: 10px;
                        font-size: 16px;
                        color: #666;
                      "
                    >
                      盈喜集团于2000年进入纺织服装领域，历经20年的发展，盈喜集团现已成为纺织服装行业内专注于产业链服务的综合性集团公司，业务范围遍及全球。
                      2016年，盈喜集团通过反向并购的方式，成功登陆美国资本市场，市值超20亿美元。作为中国纺织服装行业产业链的探索者、实践者和引领着，盈喜集团秉承资源配置最优化、资源效益最大化的基本思想，从供给侧为全球纺织服装企业提供专业化、系统化服务，促进纺织服装企业改革并转型升级，为纺织服装企业发展提供持续动力。
                    </p>
                    <div class="tpl_cont_bar">
                      <p style="display: inline-block; margin-left: 10px">
                        地址：广东省 深圳市 罗湖区 京基100 A座4805
                      </p>
                      <!-- <p style="display: inline-block; margin-left: 26px;">C区 2座 人才大厦 611</p> --><br />

                      <p style="display: inline-block; margin-left: 10px">
                        官网：www.addentax.com
                      </p>
                      <br />
                      <p style="display: inline-block; margin-left: 10px">
                        电话：0755-82330336
                      </p>
                      <br />
                      <p style="display: inline-block; margin-left: 10px">
                        Address：Rm. 4805, Blk. A, Kingkey 100, Luohu Dist.,
                        Shenzhen, Guangdong Prov., China
                      </p>

                      <!-- <p style="display: inline-block; margin-left: 10px">
                        Web： www.addentax.com
                      </p> -->
                    </div>
                    <!-- 修改结束 -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
    <!-- #content -->
    <Footers></Footers>
  </div>
</template>

<script>
import Headers from "./../components/header/header.vue";
import Footers from "./../components/footer/footer.vue";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: { Headers, Footers },
  metaInfo() {
    return {
      title: "盈喜集团联系方式_关于盈喜 - 盈喜集团股份有限公司|ATXG",
      meta: [
        {
          name: "keywords",
          content: "盈喜集团地址，盈喜联系电话，盈喜总部，深圳盈喜，ATXG",
        },
        {
          name: "description",
          content:
            "盈喜集团中国总部位于深圳市罗湖区京基100大厦，也是盈喜集团的管理和财务中心，以此作为立足中国，服务全球的枢纽。",
        },
      ],
    };
  },
  data() {
    //这里存放数据
    return {};
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
.address {
  text-align: left;
  .section_banner {
    background-image: url(../../public/home/img/bg-section-banner-contact.jpg);
  }
}
#fh5co-main {
  width: 90%;
  float: right;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  margin-bottom: 90px;
}
@media screen and (max-width: 1200px) {
  #fh5co-main {
    width: 70%;
  }
}
@media screen and (max-width: 768px) {
  #fh5co-main {
    width: 100%;
  }
}
#fh5co-main .fh5co-narrow-content {
  position: relative;
  width: 93%;
  margin: 0 auto;
  // padding: 4em 0;
  clear: both;
}
@media screen and (max-width: 768px) {
  #fh5co-main .fh5co-narrow-content {
    width: 100%;
    padding: 4em 1em;
  }
}

#fh5co-hero {
  min-height: 500px;
  background: #fff;
  width: 100%;
  float: left;
  clear: both;
}
#fh5co-hero .btn {
  font-size: 24px;
}
#fh5co-hero .btn.btn-primary {
  padding: 14px 30px !important;
}
#fh5co-hero .flexslider {
  border: none;
  z-index: 1;
  margin-bottom: 0;
}
#fh5co-hero .flexslider .slides {
  position: relative;
  overflow: hidden;
}
#fh5co-hero .flexslider .slides .overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: "";
  background: rgba(0, 0, 0, 0.3);
}
#fh5co-hero .flexslider .slides li {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center;
  min-height: 500px;
  position: relative;
}
#fh5co-hero .flexslider .flex-control-nav {
  bottom: 50%;
  z-index: 1000;
  right: 20px;
  float: right;
  width: auto;
}
#fh5co-hero .flexslider .flex-control-nav li {
  display: block;
  margin-bottom: 10px;
}
#fh5co-hero .flexslider .flex-control-nav li a {
  background: rgba(255, 255, 255, 0.2);
  box-shadow: none;
  width: 12px;
  height: 12px;
  cursor: pointer;
}
#fh5co-hero .flexslider .flex-control-nav li a.flex-active {
  cursor: pointer;
  background: transparent;
  border: 2px solid #228896;
}
#fh5co-hero .flexslider .flex-direction-nav {
  display: none;
}
#fh5co-hero .flexslider .slider-text {
  display: table;
  opacity: 0;
  min-height: 500px;
  z-index: 9;
}
#fh5co-hero .flexslider .slider-text > .slider-text-inner {
  display: table-cell;
  vertical-align: middle;
  min-height: 700px;
  padding: 2em;
}
@media screen and (max-width: 768px) {
  #fh5co-hero .flexslider .slider-text > .slider-text-inner {
    text-align: center;
  }
}
#fh5co-hero .flexslider .slider-text > .slider-text-inner h1,
#fh5co-hero .flexslider .slider-text > .slider-text-inner h2 {
  margin: 0;
  padding: 0;
  color: white;
  font-family: "Roboto", Arial, sans-serif;
}
#fh5co-hero .flexslider .slider-text > .slider-text-inner h1 {
  margin-bottom: 20px;
  font-size: 45px;
  line-height: 1.3;
  font-weight: 100;
  font-family: "Roboto", Arial, sans-serif;
}
@media screen and (max-width: 768px) {
  #fh5co-hero .flexslider .slider-text > .slider-text-inner h1 {
    font-size: 28px;
  }
}
#fh5co-hero .flexslider .slider-text > .slider-text-inner h2 {
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 30px;
  font-weight: 300;
}
#fh5co-hero .flexslider .slider-text > .slider-text-inner h2 a {
  color: rgba(34, 136, 150, 0.8);
  border-bottom: 1px solid rgba(34, 136, 150, 0.7);
}
#fh5co-hero .flexslider .slider-text > .slider-text-inner .heading-section {
  font-size: 50px;
}
@media screen and (max-width: 768px) {
  #fh5co-hero .flexslider .slider-text > .slider-text-inner .heading-section {
    font-size: 30px;
  }
}
#fh5co-hero .flexslider .slider-text > .slider-text-inner s .fh5co-lead {
  font-size: 20px;
  color: #fff;
}
#fh5co-hero
  .flexslider
  .slider-text
  > .slider-text-inner
  s
  .fh5co-lead
  .icon-heart {
  color: #d9534f;
}
#fh5co-hero .flexslider .slider-text > .slider-text-inner .btn {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #fff;
  padding: 22px 30px !important;
  border: none;
  font-weight: 500;
}
#fh5co-hero .flexslider .slider-text > .slider-text-inner .btn.btn-learn {
  background: #fff;
  color: #000;
}
#fh5co-hero .flexslider .slider-text > .slider-text-inner .btn.btn-learn:hover {
  color: #fff;
}
@media screen and (max-width: 768px) {
  #fh5co-hero .flexslider .slider-text > .slider-text-inner .btn {
    width: 100%;
  }
}
/* about */
.tpl_tle h3 {
  font-size: 20px;
  color: #000;
  letter-spacing: 0.7em;
  font-weight: 100 !important;
  margin-bottom: 20px;
}

.tpl_tle h4 {
  font-size: 60px;
  font-family: "Play", sans-serif;
  margin-bottom: 20px;
  font-weight: bold;
}

.tpl_tle h5 {
  font-size: 45px;
  font-weight: 100;
  margin-bottom: 0;
}

.tpl_tle p {
  font-size: 18px;
  letter-spacing: 0.8em;
  color: #000;
}

.animate-box p {
  line-height: 30px;
  color: #000;
}

.tpl_cont_bar p {
  font-size: 15px;
  color: #333;
  line-height: 30px;
  margin-bottom: 0;
}

.syd-sessages img {
  width: 100%;
  height: auto;
}
</style>